<template>
  <div  class="">
    <h2 class="ol-section-title">
      <span>Kategori</span>
      <i></i>
    </h2>
    <div v-if="loading" class="container-endorsement ol-home-endorsement-list">
      <SkeletonLoader />
    </div>
    <div v-else class="container-endorsement ol-home-endorsement-list">
      <div class="card-endorsement"  v-for="(item, i) in categories.data" :key="i">
        <a :href="'artikel/category/'+item.slug" >
          <div class="card-endorsement-header">
              <img :src="item.image_url" :alt="item.name_id" />
            <div style="font-size:16px;text-align: center;padding:4px;color:#364b7b;text-align: center;font-weight: 500;">{{item.name_id}}</div>
          </div>
        </a>
      </div>
      
    </div>
  </div>
</template>

<script>
import SkeletonLoader from "@/components/loading/skeletonLoader";
import {mapActions,mapGetters} from "vuex";

export default {
  name: "CategoryArticle",
  components:{
    SkeletonLoader
  },
  data() {
    return {
      loading:true
    };
  },
  computed:{
    ...mapGetters("artikel",["categories"]),
  },
  methods:{
    ...mapActions('artikel',['getCategories']),
  },
  async mounted() {
    this.loading = true;
    await this.getCategories();
    this.loading = false;
  },
  updated() {
    $(".container-endorsement").slick({
      slidesToScroll: 1,
      slidesToShow: 4,
      dots: !1,
      autoplay: !0,
      autoplaySpeed: 5e3,
      arrows: !0,
      pauseOnFocus: !1,
      infinite: !0,
      speed: 500,
      fade: !1,
      responsive: [{
        breakpoint: 891,
        settings: {
          slidesToShow: 2,
        },
      }, {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
        },
      }],
    });
  },
};
</script>

<style lang="css" scoped>
@import "../../assets/css/_endorsement.css";
@import "../../assets/js/slick/slick.css";
</style>
